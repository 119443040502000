var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',{ref:"pieces-table",staticClass:"vgt-table-checkbox-styled",attrs:{"columns":_vm.columns,"rows":_vm.pieces,"style-class":"vgt-table condensed vgt-checkbox-col","line-numbers":true,"select-options":{
      enabled: _vm.selectable,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    },"sort-options":{
      enabled: false,
    },"pagination-options":{
      enabled: true,
      mode: 'records',
      perPage: 10,
      perPageDropdown: [3, 5, 10],
      dropdownAllowAll: true,
      jumpFirstOrLast : true,
      firstLabel : 'Primera página',
      lastLabel : 'Última página',
      nextLabel: 'Siguiente',
      prevLabel: 'Anterior',
      rowsPerPageLabel: 'Piezas por página',
      ofLabel: 'de',
      allLabel: 'Todo',
    }},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',[_c('h6',{staticClass:"capitalize-col-hd"},[_c('strong',[_vm._v(_vm._s(props.column.label))])])])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'Tag')?_c('span',[_c('div',{staticClass:"d-flex justify-content-start"},[_c('h6',[_vm._v(_vm._s(props.row.Tag))]),(_vm.canShowNoReceivedTag(props.row))?_c('div',{staticClass:"sm-banner-alert alert-yellow",attrs:{"role":"alert"}},[_vm._v(" No recibida ")]):_vm._e(),(_vm.canShowUnavailableTag(props.row))?_c('div',{staticClass:"sm-banner-alert alert-gray",attrs:{"role":"alert"}},[_vm._v(" No disponible ")]):_vm._e(),(_vm.canShowTransferTag(props.row))?_c('div',{staticClass:"sm-banner-alert alert-pink",attrs:{"role":"alert"}},[_vm._v(" Traspaso ")]):_vm._e()])]):(props.column.field === 'Availability')?_c('span',[_c('h6',[_c('b-badge',{staticClass:"ava-label",attrs:{"variant":_vm.getAvailabilityStyle(props.row.Availability)}},[_vm._v(" "+_vm._s(props.row.Availability)+" ")])],1)]):(props.column.field === 'ArriveDate')?_c('span',[_c('h6',{staticClass:"ava-label"},[_vm._v(_vm._s(_vm._f("moment")(props.row.ArriveDate,"DD/MM/YYYY")))])]):(props.column.field === 'actions')?_c('span',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Eliminar'),expression:"'Eliminar'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary"},on:{"click":function($event){return _vm.deletePiece(props.row)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)],1):_c('span',[_c('h6',[_vm._v(_vm._s(props.formattedRow[props.column.field]))])])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" This will show up when there are no rows ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }